import React from "react"
import Headshot from '../images/face.jpg'
import headStyle from './projects.module.scss'
import Head from '../components/head'
import layoutStyles from '../components/layout.module.scss'
import Header from "../components/header"
import Footer from "../components/footer"
import Git from '../images/github.png'
import Linked from '../images/linked.png'
import Typical from 'react-typical'
import { Spring } from 'react-spring/renderprops'


const divStyle = {
  marginLeft: `3%`,
  borderRadius: `15%`,
  verticalAlign: `middle`
};
const first = {
  verticalAlign: `middle`
};

const IndexPage = () => {
  // AOS.init({
  //   offset: 200, // offset (in px) from the original trigger point
  //   delay: 0, // values from 0 to 3000, with step 50ms
  //   duration: 1000 // values from 0 to 3000, with step 50ms
  // });

  return (

    <div>
      <Header />
      <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
          <Head title="Home" />
          <div class={layoutStyles.row} >
            <div class={layoutStyles.column} data-sal="slide-right" data-sal-delay="200"
            data-sal-easing="ease" data-sal-duration="1000">
              <center>
                <img className={headStyle.img} src={Headshot} alt="yeet"></img>

              </center>

            </div>
            <div class={layoutStyles.column}>
              <h3><Typical
                loop={1}
                steps={[
                  'Computer Scientist',
                  1000,
                  'Data Analyst',
                  1000,
                  'Software Engineer',
                  1000
                ]}
              />
              </h3>
              <p>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}>
                  {props => <div style={props}>I am a software engineer based in Seattle with 4 years of work experience. In addition to software, I am passionate about data science, educational equity, and transportation, and I am especially excited to work on projects at the intersection of these topics!</div>}
                </Spring>
              
          </p>
              <p >
                <a href="../../EthanWuResume.pdf" target="_blank" rel="noopener noreferrer">
                  <button className={headStyle.button} style={first}>
                    RESUME
           </button>
                </a>

                <a href="https://github.com/ethanwu18" target="_blank" rel="noopener noreferrer">
                  <img src={Git} alt="Click Here to Go to GitHub" width="10%" height="10%" className={headStyle.indexBtn} style={divStyle}></img>
                </a>


                <a href='https://www.linkedin.com/in/ethan-wu-8bba22109/' target='_blank' rel="noopener noreferrer">
                  <img src={Linked} alt="Click Here to Go to LinkedIn" width="10%" height="10%" className={headStyle.indexBtn} style={divStyle} ></img>
                </a>
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>



  )
}

export default IndexPage